import React from 'react';
import Loadable from 'react-loadable';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as HeroEcommerceLogistics }
  from '@shipae/components-sandbox/component/static-icons/hero-ecommercelogistics.svg'; // eslint-disable-line
import { ReactComponent as HeroLastMileDelivery }
  from '@shipae/components-sandbox/component/static-icons/hero-lastmiledelivery.svg'; // eslint-disable-line
import { ReactComponent as HeroAirAndOceanFreight }
  from '@shipae/components-sandbox/component/static-icons/hero-ecommercelogistics.svg'; // eslint-disable-line

import { useTranslation } from 'src/views/misc/localization';
import {
  setHeroActiveTabId,
} from 'src/application/lazy/hero/actions';
import {
  getHero,
} from 'src/application/lazy/hero/selectors';
import {
  HeroMenuItemType,
} from 'src/infrastructure/gatsby/types/hero';
import EcommerceLogistics from '../../panels/ecommerce-logistics';
import {
  Loading, PanelContainer, Tabs, Tab,
} from '../styles';

const PANELS: HeroMenuItemType[] = [
  {
    id: '2',
    tabTitle: 'heroEcommerceLogisticsTabTitle',
    formTitle: 'heroEcommerceLogisticsFormTitle',
    formComponent: 'ecommerceLogistics',
    iconComponent: HeroEcommerceLogistics,
    jsx: EcommerceLogistics,
  },
  {
    id: '3',
    tabTitle: 'heroLastMileDeliveryTabTitle',
    formTitle: 'heroLastMileDeliveryFormTitle',
    formComponent: 'lastMileDelivery',
    iconComponent: HeroLastMileDelivery,
    jsx: Loadable({
      loader: () => import('../../panels/last-mile-delivery' /* webpackChunkName: "hero-last-mile-delivery" */) as any, // eslint-disable-line
      loading: () => <Loading>Loading...</Loading>,
    }),
  },
  {
    id: '4',
    tabTitle: 'heroAirAndOceanFreightTabTitle',
    formTitle: 'heroAirAndOceanFreightFormTitle',
    formComponent: 'airAndOceanFreight',
    iconComponent: HeroAirAndOceanFreight,
    jsx: Loadable({
      loader: () => import('../../panels/air-ocean-freight' /* webpackChunkName: "hero-air-ocean-freight" */) as any, // eslint-disable-line
      loading: () => <Loading>Loading...</Loading>,
    }),
  },
];

const Business: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    mobileHeroActiveTabId,
  } = useSelector(getHero);
  const activeTabId = mobileHeroActiveTabId || '2';
  const panel = PANELS.find((p: HeroMenuItemType) => p.id === activeTabId);
  const PanelJsx = panel?.jsx;

  return (
    <>
      <Tabs>
        {PANELS.map(
          (tab: HeroMenuItemType) => {
            const Icon = tab?.iconComponent;

            return (
              <Tab
                active={activeTabId === tab.id}
                onClick={() => dispatch(setHeroActiveTabId(tab.id, true))}
                data-ga-trigger
              >
                {Icon && (
                  <Icon
                    style={{ width: '4rem' }}
                  />
                )}
                {tab.tabTitle && t(tab.tabTitle)}
              </Tab>
            );
          },
        )}
      </Tabs>
      {panel && PanelJsx && (
        <PanelJsx
          id={panel.id}
          title={panel?.formTitle && t(panel.formTitle)}
          PanelContainer={PanelContainer}
        />
      )}
    </>
  );
};

export default Business;
