import React from 'react';
import TabPanel, {
  TabPanelPropsType,
} from '@shipae/components-sandbox/component/tabs/panel';
import { PrimaryButton } from '@shipae/components-sandbox/component/buttons';

import { useTranslation } from 'src/views/misc/localization';
import SmartLink from 'components/smart-link';
import { Title, PanelTheme } from '../styles';
import { PrimaryButtonThemeEx } from './styles';

const CONTACT_SALES_URL = '/shipa-ecommerce/get-in-touch';

type EcommerceLogisticsProps = {
  id: string,
  title: string,
  PanelContainer?: React.FC<TabPanelPropsType>,
};
const EcommerceLogistics: React.FC<EcommerceLogisticsProps> = ({
  id,
  title,
  PanelContainer = TabPanel,
}) => {
  const { t } = useTranslation();

  return (
    <PanelContainer id={id} theme={PanelTheme}>
      <Title>{title}</Title>
      <PrimaryButton
        href={CONTACT_SALES_URL}
        linkComponent={SmartLink}
        theme={PrimaryButtonThemeEx}
      >
        {t('contactSales')}
      </PrimaryButton>
    </PanelContainer>
  );
};

export default EcommerceLogistics;
