import styled from 'styled-components';
import PrimaryButtonTheme
  from '@shipae/components-sandbox/component/buttons/primary/theme';

export const PrimaryButtonThemeEx = {
  ...PrimaryButtonTheme,
  Button: styled(PrimaryButtonTheme.Button)`
    margin-top: 5.6rem;
  `,
};
